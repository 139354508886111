<template>
      <v-container
        id="street-lighting"
        fluid
        tag="section"
    >
        <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Section A <strong>saved</strong> successfully.
    </v-alert>
        <v-row justify="center">
            <v-col
            cols="12"
            md="12"
            >
                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      Section A: Homeowner Info
                    </div>
                    <div class="display-2 font-weight-light">
                    Superior Flooring Inc., 2053 Old Hwy 8 NW, New Brighton, MN 55112 
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    style="width:50%;"
                                    label="Date"
                                    color="black"
                                    type="date"
                                    v-model="section.column_1"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Revision #1 Date"
                                    color="black"
                                    type="date"
                                    v-model="section.column_2"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Revision #2 Date"
                                    color="black"
                                    type="date"
                                    v-model="section.column_3"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Revision #3 Date"
                                    color="black"
                                    type="date"
                                    v-model="section.column_4"
                                />
                            </v-col>

                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 1 - First Name"
                                    color="black"
                                    v-model="this.selected_user.PrimaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 1 - Last Name"
                                    color="black"
                                    v-model="this.selected_user.PrimaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 1 - Cell#"
                                    color="black"
                                    v-model="this.selected_user.CellNumber"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 1 - Email"
                                    color="black"
                                    v-model="this.selected_user.PrimaryEmailAddress"
                                />
                            </v-col>


                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 2 - First Name"
                                    color="black"
                                    v-model="this.selected_user.SecondaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 2 - Last Name"
                                    color="black"
                                    v-model="this.selected_user.SecondaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 2 - Cell#"
                                    color="black"
                                    v-model="this.selected_user.CellNumber"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 2 - Email"
                                    color="black"
                                    v-model="this.selected_user.SecondaryEmailAddress"
                                />
                            </v-col>

                            <v-col
                            cols="12"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Address & Legal"
                                    color="black"
                                    v-model="this.selected_user.Address"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Projected Closing Date"
                                    color="black"
                                    type="date"
                                    v-model="this.selected_user.BuildEnd"
                                />
                            </v-col>

                          <v-col col="12" md="12">
                                <v-header><strong>Additional Information/Notes</strong><v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_18"/></v-header>
                            </v-col>
                        </v-row>
                     </v-container>
                </v-form>
                </base-material-card>

                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      Section A: Main Living Levels
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>

                          <v-col cols="12" >FLOORING ALLOWANCES:   Carpet <strong>${{this.price_section_a.column_32}}</strong>/sq.ft.,Vinyl <strong>${{this.price_section_a.column_33}}</strong>/sq.ft.</v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="2" >Living Room/Fam. Room:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Wood','LVP','Carpet','Undecided']"
                            v-model="section.column_18">
                            </v-select></v-col>
                          <v-col cols="12" md="2" > Kitchen:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'"
                                :items="['Vinyl','Wood','LVP','Undecided']"
                                v-model="section.column_19"
                            >
                            </v-select></v-col>

                         <v-col cols="12" md="2">Dining Room:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Vinyl','Wood','LVP','Undecided']" v-model="section.column_20"
                            ></v-select></v-col>
                        </v-row>
                        <v-row> <v-col cols="12" md="2">Entry:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Vinyl','Wood','LVP','Undecided']" v-model="section.column_22"></v-select></v-col>
                       <v-col cols="12" md="2">Mud Room:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Vinyl','Tile','Wood','LVP','Undecided']" v-model="section.column_24"></v-select></v-col>
                       <v-col cols="12" md="2">*Master Bath:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Vinyl','Tile','LVP','Undecided']" v-model="section.column_26"></v-select></v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="2">Main Bath:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Vinyl','Tile','Undecided']" v-model="section.column_28"></v-select></v-col>

                      <v-col cols="12" md="2">Powder Bath:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Vinyl','Tile','Undecided']" v-model="section.column_30"></v-select></v-col>
                        <v-col cols="12" md="2">Laundry:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Vinyl','Tile','Undecided']" v-model="section.column_32"></v-select></v-col>
                        </v-row>
                        <v-row><v-col cols="12" md="2">Flex Room:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Vinyl','Tile','Wood','LVP','Carpet','Undecided']" v-model="section.column_34"></v-select></v-col>
                        </v-row>

                      <v-row>
                        <v-col cols="12" md="4"><strong>*LVP or Hardwood Flooring Allowance:</strong></v-col>
                      <v-col cols="12" md="8" ><v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_36"></v-text-field></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="2"><strong>*Tile Allowance:</strong></v-col>
                      <v-col cols="12" md="10"> <v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_31"></v-text-field></v-col>
                      </v-row>

                      <v-row><strong>Master Bath:</strong></v-row>
                      <v-row>
                        <v-col cols="12" md="2">*Tile Allowance:</v-col>
                            <v-col cols="12" md="2" ><v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_37"></v-text-field></v-col>
                        <v-col cols="12" md="2">Shower Allowance:</v-col>
                            <v-col cols="12" md="2" ><v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_38"></v-text-field></v-col>
                         <v-col cols="12" md="2">Shower Pan:</v-col>
                            <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Tile','STD. Fiberglass','Undecided']" v-model="section.column_39"></v-select></v-col>
                      </v-row>
                      <v-row><v-col cols="12" md="12"><strong>Main Bath:</strong></v-col>
                        <v-col cols="12" md="2">Tup/Shower:</v-col>
                            <v-col cols="12" md="3" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Standard Fiberglass','Undecided']" v-model="section.column_40"></v-select></v-col>
                      </v-row>
                      <v-col cols="12"><v-header><strong>Superior Flooring does NOT install kitchen countertops, therefore, builder must be made aware of any changes! Changes must be inwriting on Homeowner Change Request Form!</strong></v-header></v-col>
                        <v-row>
                          <v-col cols="12" ><strong>KITCHEN TOPS:</strong></v-col>
                          <v-col cols="12" md="2">Laminate</v-col>
                            <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_41"></v-select></v-col>
                          <v-col cols="12" md="2">Backs:</v-col>
                            <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_42"></v-select></v-col>
                          <v-col cols="12" md="2">Edges (Laminate):</v-col>
                            <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_43"></v-select></v-col>
                          <v-col cols="12" md="2">Quartz/Granite:</v-col>
                            <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Island Only','Perimeter Only','Island & Perimeter','Undecided']" v-model="section.column_45"></v-select></v-col>
                          <v-col cols="12" md="2">Quartz/Granite Allow$:</v-col>
                            <v-col cols="12" md="2" ><v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_44"></v-text-field></v-col>
                          <v-col cols="12" md="2">Painting Center Island:</v-col>
                          <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_46"></v-select></v-col>
                          </v-row>
                      <v-row>
                            <v-col cols="12" md="1" >Sink:</v-col>
                            <v-col cols="12" md="4" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Standard Stainless Steel','Undecided']" v-model="section.column_47"></v-select></v-col>
                        <v-col cols="12" md="2" >Supplied by:</v-col>
                            <v-col cols="12" md="5" > <v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_48"></v-text-field></v-col>
                      </v-row>
                      <v-row>
                            <v-col cols="12" md="1" >Stove:</v-col>
                            <v-col cols="12" md="4" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Free Standing','Cook Top','Slide In','Undecided']" v-model="section.column_49"></v-select></v-col>
                        <v-col cols="12" md="2" >Supplied by:</v-col>
                            <v-col cols="12" md="5" > <v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_50"></v-text-field></v-col>
                      </v-row>
                      <v-row>
                            <v-col cols="12" md="1" >Back Splash:</v-col>
                            <v-col cols="12" md="4" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_51"></v-select></v-col>
                        <v-col cols="12" md="2" >Allowance:</v-col>
                            <v-col cols="12" md="5" > <v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_52"></v-text-field></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="2" >Painted Kitchen Cabinets:</v-col>
                            <v-col cols="12" md="3" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_53"></v-select></v-col>
                        </v-row>
                      <v-row><strong>Bath Tops:</strong></v-row>
                        <v-row>
                            <v-col cols="12" md="1">Master:</v-col>
                          <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Laminate','Solid Surf','Granite','Undecided']" v-model="section.column_54"></v-select></v-col>
                          <v-col cols="12" md="1">Allow $:</v-col>
                            <v-col cols="12" md="2"><v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_56"></v-text-field></v-col>
                          <v-col cols="12" md="1">Backs:</v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_57"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader>Edges: (same material)</v-subheader></v-col>
                        </v-row>
                      <v-row>
                            <v-col cols="12" md="1">Main:</v-col>
                          <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Laminate','Solid Surf','Granite','Undecided']" v-model="section.column_54"></v-select></v-col>
                          <v-col cols="12" md="1">Allow $:</v-col>
                            <v-col cols="12" md="2"><v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_56"></v-text-field></v-col>
                          <v-col cols="12" md="1">Backs:</v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_57"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader>Edges: (same material)</v-subheader></v-col>
                        </v-row>
                      <v-row>
                            <v-col cols="12" md="1">1/2:</v-col>
                          <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Laminate','Solid Surf','Granite','Undecided']" v-model="section.column_54"></v-select></v-col>
                          <v-col cols="12" md="1">Allow $:</v-col>
                            <v-col cols="12" md="2"><v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_56"></v-text-field></v-col>
                          <v-col cols="12" md="1">Backs:</v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_57"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader>Edges: (same material)</v-subheader></v-col>
                        </v-row>
                      <v-row>
                            <v-col cols="12" md="1">Other:</v-col>
                          <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Laminate','Solid Surf','Granite','Undecided']" v-model="section.column_54"></v-select></v-col>
                          <v-col cols="12" md="1">Allow $:</v-col>
                            <v-col cols="12" md="2"><v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_56"></v-text-field></v-col>
                          <v-col cols="12" md="1">Backs:</v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_57"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader>Edges: (same material)</v-subheader></v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="2"><strong>LAUNDRY TOP:</strong></v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" :items="['None','Laminate','Undecided']" v-model="section.column_64"></v-select></v-col>
                          <v-col cols="12" md="1">Backs:</v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_65"></v-select></v-col>
                            <v-col cols="12" md="4"><v-subheader>Edges:same</v-subheader></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="2"><strong>FIREPLACE:</strong></v-col>
                          <v-col cols="12" md="1">Tile Front:</v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_66"></v-select></v-col>
                          <v-col cols="12" md="1">Stone Front:</v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_67"></v-select></v-col>
                        </v-row>

                        <v-row>
                          <p><strong>All TOWEL BARS  & TOILET PAPER DISPENSERS;HOMEOWNER TO PROVIDE AND INSTALL.</strong></p>
                          <p><strong>OPTIONAL:</strong>  GRANITE COUNTERTOPS:(12 STANDARD COLORS SELECTED HERE)</p>
                          <p><strong>(Homeowner responsibility to view Granite slab at EXQUISITE STONE’S Elk River Store)</strong></p>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="5">WINDOW TREATMENTS: Homeowner Request Bid:</v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_68"></v-select></v-col>
                        </v-row>
                        
                    </v-container>
                </v-form>
                </base-material-card>
               <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      Section A: Basement Level (Optional)
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>

                          <v-col cols="12" >FLOORING ALLOWANCES: Carpet <strong>${{this.price_section_a.column_32}}</strong>/sq.ft.,Vinyl <strong>${{this.price_section_a.column_33}}</strong>/sq.ft.</v-col>
                        </v-row>
                        <v-row>
                          <v-col col="12" md="2">Family Room:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Wood','LVP','Carpet','Tile','Undecided']" v-model="section.column_69"></v-select></v-col>
                          <v-col cols="12" md="2">Kitchenette/Bar Area:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Vinyl','Wood','LVP','Tile','Undecided']" v-model="section.column_70"></v-select></v-col>
                          <v-col cols="12" md="2">Rec Room:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Vinyl','Wood','LVP','Carpet','Undecided']" v-model="section.column_72"></v-select></v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="2">Bedroom:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Vinyl','Wood','LVP','Carpet','Undecided']" v-model="section.column_74"></v-select></v-col>
                          <v-col cols="12" md="2"> Bedroom:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Vinyl','Wood','Carpet','Undecided']" v-model="section.column_76"></v-select></v-col>
                          <v-col cols="12" md="2">3/4 Bath:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Vinyl','Tile','Undecided']" v-model="section.column_78"></v-select></v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="2">Laundry:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Vinyl','Tile','Undecided']" v-model="section.column_80"></v-select></v-col>
                          <v-col cols="12" md="2">Flex Room:</v-col>
                            <v-col cols="12" md="2" > <v-select :readonly="permissions === 'read:homeowner'" :items="['Vinyl','Wood','LVP','Carpet','Undecided']" v-model="section.column_82"></v-select></v-col>
                        </v-row>

                      <v-row>
                        <v-col cols="12" md="4"><strong>*LVP or Hardwood Flooring Allowance:</strong></v-col>
                      <v-col cols="12" md="8" ><v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_84"></v-text-field></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="2"><strong>*Tile Allowance:</strong></v-col>
                      <v-col cols="12" md="10"> <v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_85"></v-text-field></v-col>
                      </v-row>

                      <v-row><strong>Bath:</strong></v-row>
                      <v-row>
                        <v-col cols="12" md="2">*Tile Allowance:</v-col>
                            <v-col cols="12" md="2" ><v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_86"></v-text-field></v-col>
                        <v-col cols="12" md="2">Shower Allowance:</v-col>
                            <v-col cols="12" md="2" ><v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_87"></v-text-field></v-col>
                         <v-col cols="12" md="2">Shower Pan:</v-col>
                            <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Tile','STD. Fiberglass','Undecided']" v-model="section.column_88"></v-select></v-col>
                      </v-row>
                      <v-row><v-col cols="12" md="12"><strong>Bath:</strong></v-col>
                        <v-col cols="12" md="2">Tup/Shower:</v-col>
                            <v-col cols="12" md="3" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Standard Fiberglass','Undecided']" v-model="section.column_89"></v-select></v-col>
                      </v-row>
                      <v-col cols="12"><v-header><strong>Superior Flooring does NOT install kitchen countertops, therefore, builder must be made aware of any changes! Changes must be inwriting on Homeowner Change Request Form!</strong></v-header></v-col>
                     <v-row>
                          <v-col cols="12" ><strong>CUSTOM TOPS:</strong></v-col>
                          <v-col cols="12" md="2">Laminate</v-col>
                            <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_90"></v-select></v-col>
                          <v-col cols="12" md="2">Backs:</v-col>
                            <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_91"></v-select></v-col>
                          <v-col cols="12" md="2">Edges (Laminate):</v-col>
                            <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_92"></v-select></v-col>
                          <v-col cols="12" md="2">Quartz/Granite:</v-col>
                            <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Island Only','Perimeter Only','Island & Perimeter','Undecided']" v-model="section.column_93"></v-select></v-col>
                          <v-col cols="12" md="2">Quartz/Granite Allow$:</v-col>
                            <v-col cols="12" md="2" ><v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_94"></v-text-field></v-col>
                          <v-col cols="12" md="2">Painting Center Island:</v-col>
                          <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_95"></v-select></v-col>
                          </v-row>
                      <v-row>
                            <v-col cols="12" md="1" >Sink:</v-col>
                            <v-col cols="12" md="4" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Standard Stainless Steel','Undecided']" v-model="section.column_96"></v-select></v-col>
                        <v-col cols="12" md="2" >Supplied by:</v-col>
                            <v-col cols="12" md="5" > <v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_97"></v-text-field></v-col>
                      </v-row>
                      <v-row>
                            <v-col cols="12" md="1" >Stove:</v-col>
                            <v-col cols="12" md="4" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Free Standing','Cook Top','Slide In','Undecided']" v-model="section.column_98"></v-select></v-col>
                        <v-col cols="12" md="2" >Supplied by:</v-col>
                            <v-col cols="12" md="5" > <v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_99"></v-text-field></v-col>
                      </v-row>
                      <v-row>
                            <v-col cols="12" md="1" >Back Splash:</v-col>
                            <v-col cols="12" md="4" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_100"></v-select></v-col>
                        <v-col cols="12" md="2" >Allowance:</v-col>
                            <v-col cols="12" md="5" > <v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_101"></v-text-field></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="2" >Painted Kitchen Cabinets:</v-col>
                            <v-col cols="12" md="3" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_102"></v-select></v-col>
                        </v-row>
                      <v-row><strong>Bath Tops:</strong></v-row>
                        <v-row>
                          <v-col cols="12" md="2" ><v-select :readonly="permissions === 'read:homeowner'" :items="['Laminate','Solid Surf','Granite','Undecided']" v-model="section.column_103"></v-select></v-col>
                          <v-col cols="12" md="1">Allow $:</v-col>
                            <v-col cols="12" md="2"><v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_104"></v-text-field></v-col>
                          <v-col cols="12" md="1">Backs:</v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_105"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader>Edges: (same material)</v-subheader></v-col>
                        </v-row>
                      <v-row>
                      <v-col cols="12" md="2"><strong>LAUNDRY TOP:</strong></v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" :items="['None','Laminate','Undecided']" v-model="section.column_106"></v-select></v-col>
                          <v-col cols="12" md="1">Backs:</v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_107"></v-select></v-col>
                            <v-col cols="12" md="4"><v-subheader>Edges:same</v-subheader></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="2"><strong>FIREPLACE:</strong></v-col>
                          <v-col cols="12" md="1">Tile Front:</v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_108"></v-select></v-col>
                          <v-col cols="12" md="1">Stone Front:</v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_109"></v-select></v-col>
                        </v-row>

                        <v-row>
                          <p><strong>All TOWEL BARS  & TOILET PAPER DISPENSERS;HOMEOWNER TO PROVIDE AND INSTALL.</strong></p>
                          <p><strong>OPTIONAL:</strong>  GRANITE COUNTERTOPS:(12 STANDARD COLORS SELECTED HERE)</p>
                          <p><strong>(Homeowner responsibility to view Granite slab at EXQUISITE STONE’S Elk River Store)</strong></p>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="5">WINDOW TREATMENTS: Homeowner Request Bid:</v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_110"></v-select></v-col>
                        </v-row>

                    </v-container>
                </v-form>
                </base-material-card>

                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      Section A: Flooring Enamel
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12"><v-text-field :readonly="permissions === 'read:homeowner'" label="Home Owner" v-model="homeowners"></v-text-field></v-col>
                            <v-col cols="12"><v-text-field :readonly="permissions === 'read:homeowner'" label="Property Address" v-model="selected_user.Address"></v-text-field></v-col>
                            <v-col cols="12"><v-text-field :readonly="permissions === 'read:homeowner'" label="Enamel Color" v-model="section.column_120"></v-text-field></v-col>

                            <v-col cols="12">

                                <v-data-table
                                    :headers="headers_enamel"
                                    :items="section.enamel_details"
                                    disable-pagination
                                    :hide-default-footer="true"
                                    >

                                    <template v-slot:top>
                                    <v-toolbar
                                        flat
                                    >
                                        <v-dialog
                                            v-model="dialog_enamel"
                                            max-width="50%"
                                        >
                                        <v-card>
                                            <v-card-title>
                                                <span class="card-title font-weight-light">Edit</span>
                                            </v-card-title>
                                
                                            <v-card-text>
                                                <v-container>
                                                <v-row>
                                                    <v-col cols="12" md="6" >
                                                    <v-text-field :readonly="permissions === 'read:homeowner'" v-model="selected_enamel_item.item_desc" label="ITEM" disabled ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12"  md="6"  >
                                                    <v-text-field :readonly="permissions === 'read:homeowner'" v-model="selected_enamel_item.item_enamel" label="ENAMEL" ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" >
                                                    <v-textarea v-model="selected_enamel_item.item_notes" label="NOTES" ></v-textarea>
                                                    </v-col>
                                                </v-row>
                                                </v-container>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-btn color="blue darken-1" text @click="save_enamel" > Save </v-btn>
                                                <v-btn color="blue darken-1" text @click="close_enamel"  > Close </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                        </v-dialog>
                                    </v-toolbar>
                                    </template>

                                    <template v-slot:[`item.item_details`]="{ item }">
                                    <v-icon  small class="mr-2" @click="edit_enamel(item)" >  mdi-pencil </v-icon>
                                    </template>
                                </v-data-table>

                            </v-col>
                            
                            <v-col cols="12" ><v-subheader>NOTICE: When selecting (2) different enamel colors or (2) different stain colors, you will be charged a $300.00 equipment cleaning charge due to the additional process.</v-subheader></v-col>
                        </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                    v-model="section.column_121"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_122"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                    v-model="section.column_123"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_124"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection">Sign</v-btn>
                    </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>

                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      Section A: Flooring Stain
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12"><v-text-field :readonly="permissions === 'read:homeowner'" label="Home Owner" v-model="homeowners"></v-text-field></v-col>
                            <v-col cols="12"><v-text-field :readonly="permissions === 'read:homeowner'" label="Property Address" v-model="selected_user.Address"></v-text-field></v-col>
                            <v-col cols="12"><v-text-field :readonly="permissions === 'read:homeowner'" label="Stain Color"></v-text-field></v-col>

                            <v-col cols="12">

                                <v-data-table
                                    :headers="headers_stain"
                                    :items="section.stain_details"
                                    disable-pagination
                                    :hide-default-footer="true"
                                    >

                                    <template v-slot:top>
                                    <v-toolbar
                                        flat
                                    >
                                        <v-dialog
                                            v-model="dialog_stain"
                                            max-width="50%"
                                        >
                                        <v-card>
                                            <v-card-title>
                                                <span class="card-title font-weight-light">Edit</span>
                                            </v-card-title>
                                
                                            <v-card-text>
                                                <v-container>
                                                <v-row>
                                                    <v-col cols="12" md="6" >
                                                    <v-text-field :readonly="permissions === 'read:homeowner'" v-model="selected_stain_item.item_desc" label="ITEM" disabled ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12"  md="6"  >
                                                    <v-text-field :readonly="permissions === 'read:homeowner'" v-model="selected_stain_item.item_stain" label="ENAMEL" ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" >
                                                    <v-textarea v-model="selected_stain_item.item_notes" label="NOTES" ></v-textarea>
                                                    </v-col>
                                                </v-row>
                                                </v-container>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-btn color="blue darken-1" text @click="save_stain" > Save </v-btn>
                                                <v-btn color="blue darken-1" text @click="close_stain"  > Close </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                        </v-dialog>
                                    </v-toolbar>
                                    </template>

                                    <template v-slot:[`item.item_details`]="{ item }">
                                    <v-icon  small class="mr-2" @click="edit_stain(item)" >  mdi-pencil </v-icon>
                                    </template>
                                </v-data-table>

                            </v-col>
                            
                            <v-col cols="12" ><v-subheader>NOTICE: When selecting (2) different enamel colors or (2) different stain colors, you will be charged a $300.00 equipment cleaning charge due to the additional process.</v-subheader></v-col>
                        </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                    v-model="section.column_125"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_126"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                    v-model="section.column_127"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_128"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection">Sign</v-btn>
                    </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>

                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section A: Flooring Wall Paint
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12"><v-text-field :readonly="permissions === 'read:homeowner'" label="Home Owner" v-model="homeowners"></v-text-field></v-col>
                            <v-col cols="12"><v-text-field :readonly="permissions === 'read:homeowner'" label="Property Address" v-model="selected_user.Address"></v-text-field></v-col>
                            <v-col cols="12"><v-text-field :readonly="permissions === 'read:homeowner'" label="Wall Paint #1" v-model="section.column_131"></v-text-field></v-col>
                            <v-col cols="12"><v-textarea label="Egg Shell Upgrade Option" v-model="section.column_132"></v-textarea></v-col>
                            <v-col cols="12"><v-text-field :readonly="permissions === 'read:homeowner'" label="Wall Paint #2" v-model="section.column_133"></v-text-field></v-col>
                            <v-col cols="12"><v-textarea label="Egg Shell Upgrade Option" v-model="section.column_134"></v-textarea></v-col>
                            <v-col cols="12" ><v-subheader>NOTICE: When selecting (2) different stain colors or (2) different enamel colors, you will be charged a $300.00 equipment cleaning charge due to the additional process.</v-subheader></v-col>
                        </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                    v-model="section.column_135"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_136"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                    v-model="section.column_137"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_138"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection">Sign</v-btn>
                    </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>

                 <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section A: Paint & Stain Color Change Addendum
                    </div>
                    <div class="display-1 font-weight-light">
                    (Time Frame - At Superior Flooring Meeting)
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12"><v-text-field :readonly="permissions === 'read:homeowner'" label="Home Owner" v-model="homeowners"></v-text-field></v-col>
                            <v-col cols="12"><v-text-field :readonly="permissions === 'read:homeowner'" label="Property Address" v-model="selected_user.Address"></v-text-field></v-col>
                            <v-col cols="12">
                                <p>Homeowner understands that their new home comes with two coats of Sherwin Williams color of their choice. Superior Flooring will assist you on your paint selections and will forward those directly to the builder. If homeowner elects to go to Sherwin Williams directly, it will be homeowner’s responsibility to inform the builder if selections get changed. Any change of room color or brand of paint will result in additional charges to homeowner.</p>
                                <p>Boulder Contracting displays their assortment of standard stained & painted cabinet doors at their flooring vendor studio. Any optional selection made by the homeowner outside of the standards will be the homeowners responsibility to accept painter’s finished work. Construction of home will continue as scheduled.</p>
                                <p>(Painting kitchen cabinetry or interior mill work of new home requires builder to obtain pricing for your specific house plan. This process involves additional time and labor that may cause rescheduling of closing date.)</p>
                            </v-col>
                            <v-col cols="12" ><v-subheader>Paint selections need to be done with in4 weeks of date on your selection sheet **Otherwise; THE INTERIOR WILL BE PAINTED  IN STANDARD  WHITE!!</v-subheader></v-col>
                     <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      ></v-dialog>
                        </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                    v-model="section.column_141"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_142"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                    v-model="section.column_143"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_144"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection">Sign</v-btn>
                    </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import axios from "axios";

export default {
    name:'HomeSelectionsSuperiorFlooring',
    data () {
      return {
        dialog_enamel: false,
        dialog_stain: false,
        editedIndex:  -1,
        headers_enamel: [
                    {
                    text: 'Item',
                    sortable: false,
                    class:"primary--text",
                    value: 'item_desc',
                    },
                    { 
                    text: 'Enamel', 
                    value: 'item_enamel', 
                    sortable: false,
                    class:"primary--text",
                    },
                    { 
                    text: 'Notes', 
                    value: 'item_notes', 
                    sortable: false,
                    class:"primary--text",
                    }
                ],

        headers_stain: [
                    {
                    text: 'Item',
                    sortable: false,
                    class:"primary--text",
                    value: 'item_desc',
                    },
                    { 
                    text: 'Stain', 
                    value: 'item_stain', 
                    sortable: false,
                    class:"primary--text",
                    },
                    { 
                    text: 'Notes', 
                    value: 'item_notes', 
                    sortable: false,
                    class:"primary--text",
                    }
                    ],
        selected_enamel_item: {
            item_desc: '',
            item_enamel: '',
            item_notes:''
        },
        default_enamel_item: {
            item_desc: '',
            item_enamel: '',
            item_notes:''
        },
         selected_stain_item: {
            item_desc: '',
            item_stain: '',
            item_notes:''
        },
        default_stain_item: {
            item_desc: '',
            item_stain: '',
            item_notes:''
        },
        home_id: localStorage.getItem("homeid"),
        alert:false,
        dialog: null,
        valid: null,
        section_id: "A",
        staff_email: 'steve@skelleymarketing.com',
        add_vendor_email:'',
        vendor_email: [],
        selected_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
        default_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
        price_section_a: {
          column_32: '',
          column_33: ''
        },
        section: {
          column_1:'',
          column_2:'',
          column_3:'',
          column_4:'',
          column_5:'',
          column_6:'',
          column_7:'',
          column_8:'',
          column_9:'',
          column_10:'',
          column_11:'',
          column_12:'',
          column_13:'',
          column_15:'',
          column_16:'',
          column_17:'',
          column_18:'',
          column_19:'',
          column_20:'',
          column_21:'',
          column_22:'',
          column_23:'',
          column_24:'',
          column_25:'',
          column_26:'',
          column_27:'',
          column_28:'',
          column_29:'',
          column_30:'',
          column_31:'',
          column_32:'',
          column_33:'',
          column_34:'',
          column_35:'',
          column_36:'',
          column_37:'',
          column_38:'',
          column_39:'',
          column_40:'',
          column_41:'',
          column_42:'',
          column_43:'',
          column_44:'',
          column_45:'',
          column_46:'',
          column_47:'',
          column_48:'',
          column_49:'',
          column_50:'',
          column_51:'',
          column_52:'',
          column_53:'',
          column_54:'',
          column_55:'',
          column_56:'',
          column_57:'',
          column_58:'',
          column_59:'',
          column_60:'',
          column_61:'',
          column_62:'',
          column_63:'',
          column_64:'',
          column_65:'',
          column_66:'',
          column_67:'',
          column_68:'',
          column_69:'',
          column_70:'',
          column_71:'',
          column_72:'',
          column_73:'',
          column_74:'',
          column_75:'',
          column_76:'',
          column_77:'',
          column_78:'',
          column_79:'',
          column_80:'',
          column_81:'',
          column_82:'',
          column_83:'',
          column_84:'',
          column_85:'',
          column_86:'',
          column_87:'',
          column_88:'',
          column_89:'',
          column_90:'',
          column_91:'',
          column_92:'',
          column_93:'',
          column_94:'',
          column_95:'',
          column_96:'',
          column_97:'',
          column_98:'',
          column_99:'',
          column_100:'',
          column_101:'',
          column_102:'',
          column_103:'',
          column_104:'',
          column_105:'',
          column_106:'',
          column_107:'',
          column_108:'',
          column_109:'',
          column_110:'',
          column_111:'',
          column_112:'',
          column_113:'',
          column_114:'',
          column_115:'',
          column_116:'',
          column_117:'',
          column_118:'',
          column_119:'',
          column_120:'',
          enamel_details: [],
          column_121:'',
          column_122:'',
          column_123:'',
          column_124:'',
          stain_details: [],
          column_125:'',
          column_126:'',
          column_127:'',
          column_128:'',
          column_129:'',
          column_130:'',
          column_131:'',
          column_132:'',
          column_133:'',
          column_134:'',
          column_135:'',
          column_136:'',
          column_137:'',
          column_138:'',
          column_139:'',
          column_140:'',
          column_141:'',
          column_142:'',
          column_143:'',
          column_144:'',
          column_145:''
      },
      selected_user: {
          PrimaryEmailAddress:'',
          SecondaryEmailAddress:'',
          PrimaryFirstName: '',
          PrimaryLastName: '',
          SecondaryFirstName: '',
          SecondaryLastName: '',
          Address:'',
          City:'',
          State: '',
          Zip: '',
          BuildStart: '',
          BuildEnd:'',
          CellNumber:'',
          HomeNumber: '',
          WorkPhone:'',
          Role:'',
          Agent:''
      },
      }
    },

    watch: {
      dialog_enamel (val) {
        val || this.close_enamel()
      },
      dialog_stain (val) {
        val || this.close_stain()
      },
      
    },

    created () {
      this.getSection()
      this.initialize()
      this.getHomePrice()
      this.getVendorEmail()
      this.getUserInfo()
    },

    methods: {

        initialize () {
          this.section.enamel_details = [
            {item_desc: 'Millwork', item_enamel: '', item_notes:''},
            {item_desc: 'Doors', item_enamel: '', item_notes:''},
            {item_desc: 'Kitchen cabinetry perimeter', item_enamel: '', item_notes:''},
            {item_desc: 'Kitchen island', item_enamel: '', item_notes:''},
            {item_desc: 'Kitchen hood', item_enamel: '', item_notes:''},
            {item_desc: 'Kitchen Solid Pantry Door', item_enamel: '', item_notes:''},
            {item_desc: 'Mud room cabinetry', item_enamel: '', item_notes:''},
            {item_desc: 'Laundry room cabinetry', item_enamel: '', item_notes:''},
            {item_desc: 'Living room cabinetry (main level)', item_enamel: '', item_notes:''},
            {item_desc: 'Living room mantel (main level)', item_enamel: '', item_notes:''},
            {item_desc: 'Master bath cabinetry', item_enamel: '', item_notes:''},
            {item_desc: 'Main bath cabinetry', item_enamel: '', item_notes:''},
            {item_desc: 'Powder bath cabinetry', item_enamel: '', item_notes:''},
            {item_desc: 'J & J bath cabinetry', item_enamel: '', item_notes:''},
            {item_desc: 'Family room cabinetry (basement)', item_enamel: '', item_notes:''},
            {item_desc: 'Family room mantel (basement)', item_enamel: '', item_notes:''},
            {item_desc: 'Bar cabinetry', item_enamel: '', item_notes:''},
            {item_desc: 'Bath down cabinetry', item_enamel: '', item_notes:''},
            {item_desc: 'Flex Room Doors', item_enamel: '', item_notes:''},
            {item_desc: 'Misc1.', item_enamel: '', item_notes:''},
            {item_desc: 'Misc2.', item_enamel: '', item_notes:''},
            {item_desc: 'Misc3.', item_enamel: '', item_notes:''},
            {item_desc: 'Misc4.', item_enamel: '', item_notes:''},
            {item_desc: 'Misc5.', item_enamel: '', item_notes:''},
         
        ],
        this.section.stain_details = [
            {item_desc: 'Millwork',item_stain: '', item_notes:''},
            {item_desc: 'Doors',item_stain: '', item_notes:''},
            {item_desc: 'Kitchen cabinetry perimeter',item_stain: '', item_notes:''},
            {item_desc: 'Kitchen island',item_stain: '', item_notes:''},
            {item_desc: 'Kitchen Solid Pantry Door', item_enamel: '', item_notes:''},
            {item_desc: 'Kitchen hood',item_stain: '', item_notes:''},
            {item_desc: 'Mud room cabinetry',item_stain: '', item_notes:''},
            {item_desc: 'Laundry room cabinetry',item_stain: '', item_notes:''},
            {item_desc: 'Living room cabinetry (main level)',item_stain: '', item_notes:''},
            {item_desc: 'Living room mantel (main level)',item_stain: '', item_notes:''},
            {item_desc: 'Master bath cabinetry',item_stain: '', item_notes:''},
            {item_desc: 'Main bath cabinetry',item_stain: '', item_notes:''},
            {item_desc: 'Powder bath cabinetry',item_stain: '', item_notes:''},
            {item_desc: 'J & J bath cabinetry',item_stain: '', item_notes:''},
            {item_desc: 'Family room cabinetry (basement)',item_stain: '', item_notes:''},
            {item_desc: 'Family room mantel (basement)',item_stain: '', item_notes:''},
            {item_desc: 'Bar cabinetry',item_stain: '', item_notes:''},
            {item_desc: 'Bath down cabinetry',item_stain: '', item_notes:''},
            {item_desc: 'Flex Room Doors', item_enamel: '', item_notes:''},
            {item_desc: 'Misc1.',item_stain: '', item_notes:''},
            {item_desc: 'Misc2.',item_stain: '', item_notes:''},
            {item_desc: 'Misc3.',item_stain: '', item_notes:''},
            {item_desc: 'Misc4.',item_stain: '', item_notes:''},
            {item_desc: 'Misc5.',item_stain: '', item_notes:''},
        ]
        },
      getSection(){
          axios
          .get(process.env.VUE_APP_GET_SECTION_URL,
          {
          params: {
              home_id: this.home_id,
              section_id: this.section_id
          }
          })
          .then(response => {

              if (response.data)
              {
                  var result = response.data
                  this.section = JSON.parse(result['JsonDetails'])
              }
          })
          .catch(error => {
          console.log(error)
          })
      },
      getUserInfo(){
          axios
          .get(process.env.VUE_APP_GET_USER_URL_INFO,
          {
            params: {
              home_id: this.home_id
          }
          })
          .then(response => {

              if (response.data)
              {
                  var result = response.data
                  this.selected_user = result[0]
              }
          })
          .catch(error => {
          console.log(error)
          })
      },
      getHomePrice(){
          axios
          .get(process.env.VUE_APP_GET_HOME_SECTION_PRICES_URL,
          {
            params: {
              home_id: this.home_id
          }
          })
          .then(response => {

              if (response.data)
              {
                  var result = response.data
                  this.price_section_a = JSON.parse(result['SectionA']) || {}
              }
          })
          .catch(error => {
          console.log(error)
          })
      },
      getVendorEmail (){
        axios.get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL,{})
        .then(response => {
          this.vendor_email = response.data
        })
        .catch(error => {
          console.log(error)
        })
      },
      updateSection(){
          var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
          }

          axios
          .post(process.env.VUE_APP_UPDATE_SECTION_URL,body)
          .then(response => {
              console.log(response.data)
              this.alert = true
          })
          .catch(error => {
          console.log(error)
          })
      },
      sectionComplete(){
          var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
          }

          axios
          .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
          .then(response => {
              console.log(response.data)
              this.alert = true
              this.sendEmail([this.homeowner_emailid],this.staff_email,"home_section_submit_notification",this.selected_user.PrimaryFirstName, this.section_id)
          })
          .catch(error => {
          console.log(error)
          })
        this.add_vendor_email = Object.assign({}, this.add_vendor_email)
        this.editedIndex = -1
        this.close()
      },
      close(){
        this.dialog = false
        this.$nextTick(() => {
          this.add_vendor_email = Object.assign({}, this.add_vendor_email)
          this.editedIndex = -1
        })
      },
      sendEmail(to,from,template,content,section){

      var email_body ={
        "to": to,
        "from": from,
        "template_name": template,
        "template_data": {
          "content": content,
          "section": section
        }
      }

      axios
      .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
      .then(response => {
        console.log(response.data)
      })
      .catch(error => {
        console.log(error)
      })

      },
      edit_enamel(item){
        this.editedIndex = this.section.enamel_details.indexOf(item)
        this.selected_enamel_item = Object.assign({}, item)
        this.dialog_enamel = true
      },
      edit_stain(item){
        this.editedIndex = this.section.stain_details.indexOf(item)
        this.selected_stain_item = Object.assign({}, item)
        this.dialog_stain = true
      },
      close_enamel(){
        this.dialog_enamel = false
        this.$nextTick(() => {
          this.selected_enamel_item = Object.assign({}, this.default_enamel_item)
          this.editedIndex = -1
        })
      },
      close_stain(){
        this.dialog_stain = false
        this.$nextTick(() => {
          this.selected_stain_item = Object.assign({}, this.default_stain_item)
          this.editedIndex = -1
        })
      },
      save_enamel(){
        if (this.editedIndex > -1) {
          Object.assign(this.section.enamel_details[this.editedIndex], this.selected_enamel_item)
        } else {
          this.section.enamel_details.push(this.selected_enamel_item)
        }
        this.close_enamel()
        this.updateSection()
      },
      save_stain(){
        if (this.editedIndex > -1) {
          Object.assign(this.section.stain_details[this.editedIndex], this.selected_stain_item)
        } else {
          this.section.stain_details.push(this.selected_stain_item)
        }
        this.close_stain()
        this.updateSection()
      }
    },
  computed: {
    homeowners() {
      return `${this.selected_user.PrimaryFirstName} ${this.selected_user.PrimaryLastName}, ${this.selected_user.SecondaryFirstName} ${this.selected_user.SecondaryLastName}`
    },
    homeowner_emailid() {
        return this.selected_user.PrimaryEmailAddress
    },
    permissions() {
          return localStorage.getItem('permissions')
    },
  },
}
</script>